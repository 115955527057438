import { useLocalStorage } from "@vueuse/core";
import { requestCheckCode, requestCode, ErrorCodes, HttpCodes } from "@magnit/layer-api/api";
import Cookie from "~/utils/cookie";
import { storage } from "~/utils/consts";

interface IOTPState {
  phone: string;
  code: string;
  attempt: string;
  expire: number;
}

interface IStoredState {
  phone: string;
  expire: number;
}

interface IOTPStatus {
  code: "initial" | "pending" | "success" | "error";
  auth: "initial" | "pending" | "success" | "error";
  registerError?: "captcha";
}

export const useOTPStore = defineStore("otp", () => {
  const config = useRuntimeConfig();
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const { send } = useAnalytics();
  const { error: showToastError } = useNotification();

  const KFP_CID = config.public.kfpCid;
  const aud = useRuntimeConfig().public.aud || "";

  const state = reactive<IOTPState>({
    phone: "",
    code: "",
    attempt: "",
    expire: 0,
  });

  const savedOTPData = useLocalStorage<IStoredState>(storage.savedOTPData, {
    phone: "",
    expire: 0,
  });

  const status = reactive<IOTPStatus>({
    code: "initial",
    auth: "initial",
  });

  async function getCode(captcha?: string | null, nextPhone?: string) {
    status.code = "pending";

    if (nextPhone) {
      if (savedOTPData.value.phone === nextPhone && Date.now() < savedOTPData.value.expire) {
        state.expire = savedOTPData.value.expire;
        state.phone = savedOTPData.value.phone;
        status.code = "success";
        return;
      }

      savedOTPData.value.phone = nextPhone;
      state.phone = nextPhone;
    }

    // Получаем идентификатор сессии KFP
    const ksid = await window.kfp.login_start(KFP_CID as string, "login");
    if (ksid) {
      Cookie.set(storage.ksid, ksid);
    }

    const captchaToken = captcha || "";
    const phone = state.phone.replace(/\D/g, "");

    const { data, error } = await requestCode(aud, captchaToken, phone);

    if (data.value) {
      const expire = new Date(data.value.expires).getTime();
      savedOTPData.value.expire = expire;
      state.expire = expire;
      state.attempt = data.value.attemptId;
      status.code = "success";

      send("LoginModal:Success");
    }

    if (error.value) {
      const onError = () => {
        if (error.value?.data.code) {
          send("LoginModal:Phone:Error", {
            error_type: error.value.data.code,
          });
        }
      };

      if (error.value?.statusCode === HttpCodes.BadRequest) {
        status.code = "error";
        onError();
        return;
      }

      if (
        error.value?.statusCode === HttpCodes.Forbidden ||
        error.value?.data.code === ErrorCodes.Captcha
      ) {
        status.code = "initial";
        status.registerError = "captcha";
        return;
      }

      if (error.value?.statusCode && error.value.statusCode > HttpCodes.Error4xx) {
        onError();
        status.code = "initial";
        showToastError();
      }
    }
  }

  async function checkCode(value: string) {
    if (state.code === "") {
      status.auth = "initial";
    }

    state.code = value;

    if (value.length === 5) {
      status.auth = "pending";

      const phone = state.phone.replace(/\D/g, "");
      const { data, error } = await requestCheckCode(aud, state.code, phone, state.attempt);

      if (data.value) {
        authStore.setMagnitId(data.value.magnitIDCode);
        userStore.setSavedUUID(data.value.userId);
        status.auth = "success";
        savedOTPData.value = {
          phone: "",
          expire: 0,
        };
        return data.value;
      }

      if (error.value?.statusCode && error.value.statusCode >= HttpCodes.Error4xx) {
        const onError = () => {
          if (error.value?.data.code) {
            send("LoginModal:Error", {
              type: authStore.getRegister() ? "registration" : "login",
              error_type: error.value?.data.code,
              text: error.value?.data.message,
            });
          }
        };

        if (error.value.statusCode === HttpCodes.BadRequest) {
          status.auth = "error";
          onError();
          return;
        }

        if (error.value.statusCode > HttpCodes.Error4xx) {
          onError();
          status.auth = "initial";
          showToastError();
        }
      }
    }
  }

  return {
    state,
    status,
    getCode,
    checkCode,
  };
});
